<template>
  <div>
    <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
      <div class="inside-navigation grid-container">
        <div class="navigation-branding">
          <div class="site-logo">
            <a href="https://whatsplusapk.com/" title="GBWhatsApp" rel="home">
              <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp" title="GBWhatsApp">
            </a>
          </div>
          <p class="main-title" itemprop="headline">
            <a href="https://whatsplusapk.com/" rel="home">
              GB WhatsApp App
            </a>
          </p>
        </div>

        <div id="primary-menu" class="main-nav" v-if="isMobile>768">
          <ul id="menu-primary-menu" class="menu sf-menu">
            <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
              <a href="https://whatsplusapk.com/" aria-current="page">GB WhatsApp</a>
            </li>
            <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="https://whatsplusapk.com/gbwhatsapp-apk-download/">Download</a>
            </li>
            <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="https://whatsplusapk.com/gb-whatsapp-web/">GB WhatsApp Web</a>
            </li>
          </ul>
        </div>
        <div class="menu-toggle" v-else>
          <div @click="toggleLanguageMenu">
            <div class="menu-toggle-bar"></div>
            <div class="menu-toggle-bar"></div>
            <div class="menu-toggle-bar"></div>
          </div>
        </div>
      </div>
    </nav>
    <ul class="menu-toggle-menu" v-show="menuVisible">
      <li>
        <a href="https://whatsplusapk.com/" aria-current="page">GB WhatsApp</a>
      </li>
      <li>
        <a href="https://whatsplusapk.com/gbwhatsapp-apk-download/">Download</a>
      </li>
      <li>
        <a href="https://whatsplusapk.com/gb-whatsapp-web/">GB WhatsApp Web</a>
      </li>
    </ul>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
export default {
  name: "headerNav",
  data() {
    return {
      menuVisible: false,
      isMobile:0,
      lang: [{
        lang: 'en',
        val: 'English'
      },
      {
        lang: 'pt',
        val: 'Português'
      },
      {
        lang: 'es',
        val: 'Español'
      },
      {
        lang: 'hi',
        val: 'हिन्दी'
      }
      ],
    }
  },
  mounted() {
    this.isMobile = window.innerWidth;
  },
  methods: {
    toggleLanguageMenu() {
      this.menuVisible = !this.menuVisible;
      console.log(this.menuVisible);
    },

  }
}
</script>
<style scoped>
.menu-toggle{
  display:flex;
  flex-direction: column;
  margin-right: 10px;
}
.menu-toggle-bar{
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px 0;
  transition: all 0.3s ease-in-out;
}
</style>