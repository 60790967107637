export default {
  blogList: [
    {
      title: 'How to Use GBWhatsApp',
      desc: 'GBWhatsApp is a popular modded version of WhatsApp that offers a range of features that are not available in the official app. Here is a guide on how to use GBWhatsApp.',
      url: '/how-to-use-gb-whatsapp/',
      name: 'blog-1',
      poster: require('@/assets/blog-1.webp'),
      alt: 'How to Use GBWhatsApp',
      date: '2024-12-26',
    },
    {
      title: 'Single Tick in WhatsApp: What It Means and How It Works',
      desc: 'When using WhatsApp, the check marks next to your messages play an important role in letting you know the status of your communication. Among these, the single tick is one',
      url: '/single-ticks-whatsapp-gb/',
      name: 'blog-2',
      poster: require('@/assets/blog-2.webp'),
      alt: 'Single Tick in WhatsApp',
      date: '2024-12-28',
    },
    {
      title: 'Anti Revoke in WhatsApp: How to Enable it?',
      desc: 'If you\'ve ever wondered how to view deleted messages on WhatsApp, the Anti Revoke feature might be the answer. Available through WhatsApp Mods like GB WhatsApp, this feature',
      url: '/anti-revoke-whatsapp-gb/',
      name: 'blog-3',
      poster: require('@/assets/blog-3.webp'),
      alt: 'Anti Revoke in WhatsApp: How to Enable it?',
      date: '2025-01-11',
    },
    {
      title: 'How to Hide Forwarded Tag in GB WhatsApp',
      desc: 'If you\'re a regular GB WhatsApp user, you\'ve likely encountered the "Forwarded" tag that appears at the top of messages that are forwarded from other users.',
      url: '/disable-forwarded-message-gbwhatsapp/',
      name: 'blog-4',
      poster: require('@/assets/blog-4.webp'),
      alt: 'hide forwared tag gbwhatsapp',
      date: '2025-03-04',
    },
    {
      title: 'How to Update GB WhatsApp',
      desc: 'GB WhatsApp is a modified version of the official WhatsApp Messenger that provides additional features and customization options. Many users prefer GB WhatsApp because of these extra features, but since it is not available on the Google Play Store, updating it requires manual steps.',
      url: '/how-to-update-gbwhatsapp/',
      name: 'blog-5',
      poster: require('@/assets/how-to-update-gb-whatsapp.webp'),
      alt: 'how to update gbwhatsapp',
      date: '2025-03-19',
    },
    {
      title: 'How to Change Themes of WhatsApp [Using GB WhatsApp]',
      desc: 'Learn how to change WhatsApp themes using GBWhatsApp for a customized chat experience. Explore features, installation steps, and ban risk precautions.',
      url: '/change-themes-whatsapp/',
      name: 'blog-6',
      poster: require('@/assets/change-themes.webp'),
      alt: 'change themes gbwhatsapp',
      date: '2025-03-27',
    },
  ]
}
